import React, { useEffect, useState } from "react";
import { UserIcon } from "@heroicons/react/20/solid";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { images } from "../../assets/images";
import { Message, Submit, Thought } from "../../hooks/use-chat";
import {
  RenderComponent,
  RenderComponents,
} from "./message-actions/render-component";
import ChatGPTLoadingUI from "../ui/agent-thinking-loader";
import { Loader2 } from "lucide-react";

interface MessagesProps {
  chatId: string;
  setSubmitted: (submitted: boolean) => void;
  setMessages: React.Dispatch<React.SetStateAction<Message[]>>;
  sendMessageHidden: (message: string) => Promise<void>;
  submit: (data: Submit) => void;
  messages: Message[];
  loading?: boolean;
  accessToken: string;
  currentTransaction: number | null;
  completeTransaction: (id: number) => void;
  thoughtSteps: Thought[];
  setThoughtSteps: React.Dispatch<React.SetStateAction<Thought[]>>;
}

const Messages: React.FC<MessagesProps> = ({
  chatId,
  setMessages,
  submit,
  sendMessageHidden,
  messages,
  loading,
  accessToken,
  thoughtSteps,
}) => {
  const [current, setCurrent] = useState<number>(0);
  const [processed, setProcessed] = useState<number>(0);

  const advanceCurrent = (startFrom?: number) => {
    const start = startFrom !== undefined ? startFrom : processed + 1;
    const newCurrent = messages.findIndex(
      (message, index) =>
        index >= start &&
        message.agent === "ui" &&
        message.component !== RenderComponents.TX_STATUS
    );
    setCurrent(newCurrent === -1 ? messages.length : newCurrent);
  };

  useEffect(() => {
    advanceCurrent();
  }, [messages.length]);

  const renderMessageContent = (message: Message) => {
    switch (message.agent) {
      case "human":
        return (
          <pre className="text-sm mt-1 break-words whitespace-pre-wrap">
            {message.message}
          </pre>
        );
      case "thought":
        return (
          message.thoughts && (
            <ChatGPTLoadingUI thoughts={message.thoughts} isLoading={loading} />
          )
        );
      case "ui":
        return (
          <RenderComponent
            chatId={chatId}
            submit={submit}
            setMessages={setMessages}
            sendMessageHidden={sendMessageHidden}
            accessToken={accessToken}
            component={message.component ?? "-"}
            data={message.data}
            active={
              current === messages.indexOf(message) &&
              messages.indexOf(message) > processed
            }
            onDone={() => {
              setProcessed(messages.indexOf(message));
              advanceCurrent(messages.indexOf(message) + 1);
            }}
          />
        );
      default:
        return (
          <pre className="break-words whitespace-pre-wrap">
            <Markdown
              remarkPlugins={[remarkGfm]}
              components={{
                a: ({ node, ...props }) => (
                  <a
                    className="text-blue-600 underline"
                    {...props}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {props.children}
                  </a>
                ),
              }}
            >
              {message.message}
            </Markdown>
          </pre>
        );
    }
  };

  return (
    <div className="max-h-[90vh] overflow-y-auto py-14 px-4">
      <div
        id="chat"
        className="flex-col w-full overflow-y-scroll pb-4 max-w-5xl mx-auto"
      >
        {messages.map((message, index) => {
          const prevAgent =
            index > 0 && ["ui", "ai"].includes(messages[index - 1].agent);
          const currAgent = ["ui", "ai"].includes(message.agent);
          return (
            <div
              key={index}
              className={`flex flex-row ${
                prevAgent && !currAgent ? "my-10" : "mb-2"
              }`}
            >
              {message.agent !== "thought" && (
                <div className="min-w-8">
                  <div
                    className={`w-8 h-8 rounded-full flex justify-center items-center overflow-hidden ${
                      message.agent === "human" ? "bg-blue-500" : "bg-green-500"
                    }`}
                  >
                    {message.agent === "human" ? (
                      <UserIcon className="w-6 h-6 text-white" />
                    ) : (
                      <img alt="pfp" src={images.BuilderSupport} />
                    )}
                  </div>
                </div>
              )}
              <div className="ml-3 max-w-screen-2xl w-full">
                <span className="text-[#f3f4f6]">
                  {message.agent === "human"
                    ? "You"
                    : message.agent !== "thought"
                    ? "Surface Area"
                    : ""}
                </span>
                <div className="text-gray-300 w-full">
                  {renderMessageContent(message)}
                </div>
              </div>
            </div>
          );
        })}
        {loading && (
          <>
            <div
              className={`flex flex-col space-y-4 py-4 ${
                !loading ? "-mt-4" : "mt-2"
              } rounded-lg`}
            >
              <div className="flex items-start space-x-4">
                <div className="flex-shrink-0">
                  <div className="w-8 h-8 bg-green-500 rounded-full flex items-center justify-center">
                    <Loader2 className="w-5 h-5 text-white animate-spin" />
                  </div>
                </div>
                <div className="flex-grow">
                  <div className="flex items-center">
                    <span className="text-sm font-medium text-gray-300">
                      Surface Area
                    </span>
                    <span className="ml-2 text-xs text-gray-500">
                      Thinking...
                    </span>
                  </div>
                  <div className="mt-2">
                    <div className="flex items-center space-x-1">
                      {[1, 2, 3].map((i) => (
                        <div
                          key={i}
                          className="w-2 h-2 bg-gray-500 rounded-full animate-pulse"
                          style={{ animationDelay: `${i * 150}ms` }}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <ChatGPTLoadingUI thoughts={thoughtSteps} isLoading={loading} />
          </>
        )}
      </div>
    </div>
  );
};

export default Messages;
