export enum AgentSupportedActions {
  SWAP = "SWAP",
  BRIDGE = "BRIDGE",
  DEX = "DEX",
  PERPS = "PERPS",
  BALANCE = "BALANCE",
  ONRAMP = "ONRAMP",
  TRANSFER = "TRANSFER",
  NFT = "NFT",
  STAKING = "STAKING",
}
export interface AgentProps {
  name: string;
  examples: string[];
  description?: string;
  active: boolean;
  function?: string;
  supportedChains: string[];
  action: AgentSupportedActions;
  img?: string;
  parent?: string;
}
