import {
  Dialog,
  DialogContent,
  DialogTrigger,
  DialogHeader,
  DialogTitle,
  DialogDescription
} from "./ui/dialog";

const AgentBuilderDemoButton = () => {
  return (
    <>
      <Dialog>
        <DialogTrigger>
          <button className="underline text-xs sm:text-sm text-white text-nowrap hover:cursor-pointer hover:text-blue-500">
            Agent Builder <span className="font-bold">(Coming Soon)</span>
          </button>
        </DialogTrigger>
        <DialogContent className="max-w-[65rem]">
          <DialogHeader className="flex flex-col justify-center items-center">
            <DialogTitle>Agent Builder</DialogTitle>
            <DialogDescription>
              Join the waitlist to get access to the agent builder:
              {" "}
              <button
                className="underline text-xs sm:text-sm text-white text-nowrap hover:cursor-pointer hover:text-blue-500"
                onClick={() => {
                  window.open("https://getwaitlist.com/waitlist/20077", "_blank");
                }}
              >
                Join Here
              </button>
            </DialogDescription>
          </DialogHeader>
          <div
            style={{
              position: "relative",
              paddingBottom: "56.25%",
              height: "0"
            }}
          >
            <iframe
              src="https://www.loom.com/embed/5456fa084cd542ccad2161f1f7eeb439?sid=203262c1-c3ff-4c54-b3eb-00bcad19c5a9"
              allowFullScreen={true}
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%"
              }}
            />
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export { AgentBuilderDemoButton };
