import React, { useState, useRef, useEffect } from "react";
import { ChevronDown, ChevronUp } from "lucide-react";

interface Thought {
  title: string;
  content: string;
}

interface ChatGPTLoadingUIProps {
  thoughts: Thought[];
  isLoading: boolean | undefined;
}

const ChatGPTLoadingUI: React.FC<ChatGPTLoadingUIProps> = ({
  thoughts,
  isLoading,
}) => {
  const [isOpen, setIsOpen] = useState(true);
  const scrollRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
    if (thoughts.length > 0) {
      setIsOpen(true);
    }
  }, [thoughts]);

  return (
    <>
      {thoughts.length > 0 && (
        <div
          className={`flex flex-col space-y-2 p-4 ${
            !isLoading ? "-mt-4" : "mt-2"
          } mx-8 bg-gray-800 rounded-lg`}
        >
          <button
            onClick={() => setIsOpen(!isOpen)}
            className="flex items-center text-sm text-gray-400 hover:text-gray-300 focus:outline-none"
          >
            {isOpen ? (
              <ChevronUp className="w-4 h-4 mr-1" />
            ) : (
              <ChevronDown className="w-4 h-4 mr-1" />
            )}
            {isOpen ? "Hide thoughts" : "show thoughts"}
          </button>
          {isOpen && (
            <div
              className={`mt-2 max-h-60 overflow-y-auto pr-2 scrollbar-thin scrollbar-thumb-green-500 scrollbar-track-gray-800 ${
                isLoading && "animate-pulse"
              }`}
            >
              <div className="border-l-2 border-green-500 pl-4 space-y-2">
                {thoughts.map((thought, index) => (
                  <div key={index} className="text-sm text-gray-300">
                    <p className="font-semibold text-base">{thought.title}</p>
                    <p className="italic">{thought.content}</p>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default ChatGPTLoadingUI;
