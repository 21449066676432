import ExecutorGpt from "./screens/executor-gpt";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { WalletContextProviders } from "./hooks/wallets/context";
import { ChatProvider } from "./hooks/use-chat";
import { PreferencesProvider } from "./hooks/use-preferences";
function App() {
  return (
    <BrowserRouter>
      <PreferencesProvider>
        <WalletContextProviders>
          <ChatProvider>
            <Routes>
              <Route path="/" element={<ExecutorGpt />} />
            </Routes>
          </ChatProvider>
        </WalletContextProviders>
      </PreferencesProvider>
    </BrowserRouter>
  );
}

export default App;
