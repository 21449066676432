import { createSlice } from "@reduxjs/toolkit";
import { AgentProps } from "../../components/agent-functionality";

export interface GPTState {
  conversationStarters: Array<string>;
  preferredAgent: AgentProps | null;
}

const initialState: GPTState = {
  conversationStarters: ["Integrate Polygon", "Integrate Avalanche"],
  preferredAgent: null
};

const gptSlice = createSlice({
  name: "gpt",
  initialState,
  reducers: {
    addConversationStarter: (state, action) => {
      state.conversationStarters.push(action.payload);
    },
    selectPreferredAgent: (state, { payload }: { payload: AgentProps | null }) => {
      state.preferredAgent = payload;
      if (payload !== null) {
        state.conversationStarters =
          payload.examples ?? state.conversationStarters;
      } else {
        state.conversationStarters = [];
      }
    },
  },
});

export const { addConversationStarter, selectPreferredAgent } = gptSlice.actions;
export default gptSlice.reducer;
