import SphereLogo from "./SphereIconLogoGreen.png";
import BuilderVector from "./builder-vector.png";
import DefaultCryptoLogo from "./DefaultCryptoLogo.png";
import AnotherSphereLogo from "./Sphere.svg";

export const images = {
  AnotherSphereLogo: AnotherSphereLogo,
  SphereLogo: SphereLogo,
  BuilderSupport: BuilderVector,
  DefaultCryptoLogo: DefaultCryptoLogo,
  DefaultAgentLogo: "https://firebasestorage.googleapis.com/v0/b/sphereone-testing.appspot.com/o/Frame-9.png?alt=media&token=9c05b74d-9948-445f-936d-18f253b61b75"
};
