import { XMarkIcon } from "@heroicons/react/20/solid";
import { useEffect, useRef, useState } from "react";
import { WalletType, useWallets } from "../hooks/wallets/use-wallets";
import {
  WalletProvider,
  walletProviderLabels,
} from "../hooks/wallets/providers/wallet-provider";
import { updateBalances } from "../redux/slices/user";
import { serverApi } from "../services/server";
import { useAppDispatch } from "../redux/hooks";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "./ui/dialog";

export default function ConnectWalletButton(props: {
  className?: string;
  chatStarted?: boolean;
}) {
  const {
    addresses,
    connect,
    loading,
    providers,
    disconnect,
    agentsPreference,
    setAgentsPreference,
  } = useWallets();
  const dispatch = useAppDispatch();
  const [showDialog, setShowDialog] = useState(false);
  const loadingBalances = useRef(false);

  useEffect(() => {
    if (
      addresses.EVM &&
      addresses.SOLANA &&
      addresses.SUI &&
      addresses.COSMOS &&
      addresses.BITCOIN &&
      !loadingBalances.current
    ) {
      loadingBalances.current = true;
      serverApi
        .getBalances(
          addresses.EVM,
          addresses.SOLANA,
          addresses.SUI,
          addresses.COSMOS,
          addresses.BITCOIN
        )
        .then((balances) => dispatch(updateBalances(balances)))
        .finally(() => {
          loadingBalances.current = false;
        });
    }
  }, [addresses]);

  return (
    <div className="flex gap-2">
      <Dialog open={showDialog} onOpenChange={(open) => setShowDialog(open)}>
        <DialogTrigger
          onClick={() => setShowDialog(true)}
          className="bg-blue-500 px-3 py-2 text-sm rounded-md text-white cursor-pointer"
        >
          ({Object.values(providers).filter((w) => w !== null).length})
          Connected wallets
        </DialogTrigger>
        <DialogContent aria-describedby="wallets">
          <DialogHeader>
            <DialogTitle>Connect wallet</DialogTitle>
          </DialogHeader>
          <div className="space-y-4 mt-4">
            {/* EVM */}
            <div className="space-y-2">
              <span>EVM</span>
              {loading[WalletType.EVM] && <p>Connecting wallet...</p>}
              {(loading[WalletType.EVM] === false ||
                (addresses.EVM && providers.EVM != null)) && (
                <>
                  {addresses.EVM && providers.EVM != null ? (
                    <div className=" bg-gray-800 w-full px-4 text-xs sm:text-sm py-2 rounded-md text-left flex justify-between items-center">
                      <span>{addresses.EVM}</span>
                      <button onClick={() => disconnect(WalletType.EVM)}>
                        <XMarkIcon className="h-5 w-5 text-white" />
                      </button>
                    </div>
                  ) : (
                    <button
                      onClick={async () => {
                        await connect(WalletType.EVM, WalletProvider.EVM);
                        setShowDialog(false);
                      }}
                      className="block bg-gray-800 w-full px-4 py-2 rounded-md text-left"
                    >
                      {walletProviderLabels[WalletProvider.EVM]}
                    </button>
                  )}
                </>
              )}
            </div>

            {/* APTOS */}
            <div className="space-y-2">
              <span>APTOS</span>
              {loading[WalletType.APTOS] && <p>Connecting wallet...</p>}
              {(loading[WalletType.APTOS] === false ||
                (addresses.APTOS && providers.APTOS != null)) && (
                <>
                  {addresses.APTOS && providers.APTOS != null ? (
                    <div className=" bg-gray-800 w-full px-4 text-xs sm:text-sm py-2 rounded-md text-left flex justify-between items-center">
                      <p className="break-all">{addresses.APTOS}</p>
                      <button onClick={() => disconnect(WalletType.APTOS)}>
                        <XMarkIcon className="h-5 w-5 text-white" />
                      </button>
                    </div>
                  ) : (
                    <button
                      onClick={async () => {
                        await connect(WalletType.APTOS, WalletProvider.APTOS);
                        setShowDialog(false);
                      }}
                      className="block bg-gray-800 w-full px-4 py-2 rounded-md text-left"
                    >
                      {walletProviderLabels[WalletProvider.APTOS]}
                    </button>
                  )}
                </>
              )}
            </div>

            {/* COSMOS */}
            <div className="space-y-2">
              <span>COSMOS</span>
              {loading[WalletType.COSMOS] && <p>Connecting wallet...</p>}
              {(loading[WalletType.COSMOS] === false ||
                (addresses.COSMOS && providers.COSMOS != null)) && (
                <>
                  {addresses.COSMOS && providers.COSMOS != null ? (
                    <>
                      <div className=" bg-gray-800 w-full px-4 text-xs sm:text-sm py-2 rounded-md text-left flex justify-between items-center">
                        <p className="break-all">{addresses.COSMOS}</p>
                        <button onClick={() => disconnect(WalletType.COSMOS)}>
                          <XMarkIcon className="h-5 w-5 text-white" />
                        </button>
                      </div>
                    </>
                  ) : (
                    <button
                      onClick={async () => {
                        await connect(WalletType.COSMOS, WalletProvider.COSMOS);
                        setShowDialog(false);
                      }}
                      className="block bg-gray-800 w-full px-4 py-2 rounded-md text-left"
                    >
                      {walletProviderLabels[WalletProvider.COSMOS]}
                    </button>
                  )}
                </>
              )}
            </div>

            {/* SOL */}
            <div className="space-y-2">
              <span>SOLANA</span>
              {loading[WalletType.SOLANA] && <p>Connecting wallet...</p>}
              {(loading[WalletType.SOLANA] === false ||
                (addresses.SOLANA && providers.SOLANA != null)) && (
                <>
                  {addresses.SOLANA && providers.SOLANA != null ? (
                    <div className=" bg-gray-800 w-full px-4 py-2 rounded-md text-left text-sm sm:text-sm flex justify-between items-center">
                      <span>{addresses.SOLANA}</span>
                      <button onClick={() => disconnect(WalletType.SOLANA)}>
                        <XMarkIcon className="h-5 w-5 text-white" />
                      </button>
                    </div>
                  ) : (
                    <>
                      <button
                        onClick={async () => {
                          await connect(
                            WalletType.SOLANA,
                            WalletProvider.PHANTOM
                          );
                          setShowDialog(false);
                        }}
                        className="block bg-gray-800 w-full px-4 py-2 rounded-md text-left"
                      >
                        {walletProviderLabels[WalletProvider.PHANTOM]}
                      </button>
                      <button
                        onClick={async () => {
                          await connect(
                            WalletType.SOLANA,
                            WalletProvider.TIPLINK
                          );
                          setShowDialog(false);
                        }}
                        className="block bg-gray-800 w-full px-4 py-2 rounded-md text-left"
                      >
                        {walletProviderLabels[WalletProvider.TIPLINK]}
                      </button>
                    </>
                  )}
                </>
              )}
            </div>

            {/* SUI */}
            <div className="space-y-2">
              <span>SUI</span>
              {loading[WalletType.SUI] && <p>Connecting wallet...</p>}
              {(loading[WalletType.SUI] === false ||
                (addresses.SUI && providers.SUI != null)) && (
                <>
                  {addresses.SUI && providers.SUI != null ? (
                    <div className=" bg-gray-800 w-full px-4 py-2 rounded-md text-left text-sm sm:text-sm flex justify-between items-center">
                      <p className="break-all">{addresses.SUI}</p>
                      <button onClick={() => disconnect(WalletType.SUI)}>
                        <XMarkIcon className="h-5 w-5 text-white" />
                      </button>
                    </div>
                  ) : (
                    <>
                      <button
                        onClick={async () => {
                          await connect(WalletType.SUI, WalletProvider.SUI);
                          setShowDialog(false);
                        }}
                        className="block bg-gray-800 w-full px-4 py-2 rounded-md text-left"
                      >
                        {walletProviderLabels[WalletProvider.SUI]}
                      </button>
                    </>
                  )}
                </>
              )}
            </div>

            {/* BITCOIN */}
            <div className="space-y-2">
              <span>BITCOIN</span>
              {loading[WalletType.BITCOIN] && <p>Connecting wallet...</p>}
              {(loading[WalletType.BITCOIN] === false ||
                (addresses.BITCOIN && providers.BITCOIN != null)) && (
                <>
                  {addresses.BITCOIN && providers.BITCOIN != null ? (
                    <>
                      <div className=" bg-gray-800 w-full px-4 text-xs sm:text-sm py-2 rounded-md text-left flex justify-between items-center">
                        <span>{addresses.BITCOIN}</span>
                        <button onClick={() => disconnect(WalletType.BITCOIN)}>
                          <XMarkIcon className="h-5 w-5 text-white" />
                        </button>
                      </div>
                    </>
                  ) : (
                    <button
                      onClick={async () => {
                        await connect(
                          WalletType.BITCOIN,
                          WalletProvider.BITCOIN
                        );
                        setShowDialog(false);
                      }}
                      className="block bg-gray-800 w-full px-4 py-2 rounded-md text-left"
                    >
                      {walletProviderLabels[WalletProvider.BITCOIN]}
                    </button>
                  )}
                </>
              )}
            </div>

            {/* agents wallets */}
            <div className="flex space-x-2">
              <input
                type="checkbox"
                name="use-agents"
                id="use-agents"
                checked={agentsPreference}
                disabled={props.chatStarted}
                onChange={() => setAgentsPreference(!agentsPreference)}
              />
              <label htmlFor="use-agents">
                Use agents wallets for more flexibility and power
              </label>
            </div>
            {agentsPreference && (
              <div className="bg-gray-700 p-4 rounded-md">
                <p className="text-gray-300 text-sm">
                  Agent wallets are managed by the agents. So you can confirm
                  transactions with natural language and ask more complex tasks
                  without much involvement. To get started you'll need to add
                  funds to them. You can check your address right above.
                </p>
              </div>
            )}
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}
