import ChatEmptyScreen from "../components/chat/chat-empty-screen";
import { useChat } from "../hooks/use-chat";
import Messages from "../components/chat/messages";
import ChatInput from "../components/chat/chat-input";
import { useEffect, useState } from "react";
import { onAuthStateChanged, signInAnonymously } from "firebase/auth";
import { auth } from "../utils/firebase";
import { AlertDialog } from "../components/themed/alert-dialog";
import TokenConfetti from "../components/TokenConfetti";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import Header from "../components/header";
import { getCountryCode } from "../redux/slices/userThunk";

export default function ExecutorGpt() {
  return <ExecutorGptView />;
}

function ExecutorGptView() {
  const [tokenConfetti, setTokenConfetti] = useState<string | undefined>();
  const starters: string[] = useAppSelector(
    (state) => state.gpt.conversationStarters
  );

  const {
    chatId,
    messages,
    setMessages,
    thoughtSteps,
    setThoughtSteps,
    loading,
    appendMessage,
    sendMessageHidden,
    input,
    setInput,
    accessToken,
    setAccessToken,
    error,
    setError,
    submit,
    setSubmitted,
    currentTransaction,
    completeTransaction,
  } = useChat();

  const { destinationToken } = useAppSelector((state) => state.user);
  const { preferredAgent } = useAppSelector((state) => state.gpt);
  const dispatch = useAppDispatch();
  useEffect(() => {
    destinationToken && setTokenConfetti(destinationToken);
  }, [destinationToken]);

  useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        setAccessToken(await user.getIdToken(true));
      } else {
        setAccessToken(null);
        signInAnonymously(auth);
      }
    });
    dispatch(getCountryCode());
  }, []);

  return (
    <>
      <ErrorAlert error={error} setError={setError} />

      {/* main */}
      <div className="flex flex-col h-screen overflow-hidden">
        {/* Header */}
        <Header
          chatStarted={messages.length > 0}
        />

        {/* Chat and leaderboard */}
        <div className="flex-grow">
          {tokenConfetti && (
            <div>
              <TokenConfetti tokenUrl={tokenConfetti} />
            </div>
          )}

          <div className="flex h-[calc(100vh-3.5rem)] relative">
            {/* Chat Window */}
            <div className={`relative w-full bg-gray-900 p-4`}>
              <div className="h-full overflow-scroll">
                {messages.length === 0 ? (
                  <ChatEmptyScreen
                    setInput={setInput}
                    chatStarted={messages.length > 0}
                  />
                ) : (
                  <Messages
                    completeTransaction={completeTransaction}
                    currentTransaction={currentTransaction}
                    submit={submit}
                    accessToken={accessToken ?? ""}
                    chatId={chatId}
                    setSubmitted={setSubmitted}
                    messages={messages}
                    setMessages={setMessages}
                    thoughtSteps={thoughtSteps}
                    setThoughtSteps={setThoughtSteps}
                    sendMessageHidden={sendMessageHidden}
                    loading={loading}
                  />
                )}
              </div>

              {/* Input Field  */}
              <div className="px-4 pb-4 space-y-2 absolute z-10 bottom-0 left-0 right-0 w-full">
                <div className="mx-auto w-full sm:w-2/3">
                  {/* example inputs */}
                  {messages.length === 0 && preferredAgent !== null && (
                    <div className="space-y-2 py-2">
                      {starters.map((s) => (
                        <button
                          key={s}
                          onClick={() =>
                            appendMessage(
                              s.concat(" ", preferredAgent?.name ?? "")
                            )
                          }
                          className="bg-gray-700 rounded-md px-4 mr-2 py-2 text-left"
                        >
                          <p className="text-gray-300">{s}</p>
                        </button>
                      ))}
                    </div>
                  )}

                  {preferredAgent && (
                    <>
                      <ChatInput
                        loading={loading}
                        messages={messages}
                        input={input}
                        setInput={setInput}
                        appendMessage={appendMessage}
                        onClick={() => appendMessage()}
                      />
                      <p className="text-white text-center mx-auto w-full sm:w-2/3 text-sm italic mt-1">
                        Caution: Agent responses may contain errors. Always
                        verify the information provided.
                      </p>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function ErrorAlert(props: {
  error: string;
  setError: (e: string) => unknown;
}) {
  return (
    <AlertDialog
      open={!!props.error}
      title={"Oops!"}
      description={props.error}
      actionLabel="Accept"
      onActionPress={() => props.setError("")}
    />
  );
}
