import { createSlice } from "@reduxjs/toolkit";
import { getCountryCode } from "./userThunk";
import { Balance } from "../../components/chat/balances";

export interface UserState {
  countryCode: string;
  balances?: Balance[];
  destinationToken?: string;
}

const initialState: UserState = {
  countryCode: "US"
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    updateBalances: (state, action) => {
      state.balances = action.payload;
    },
    updateTokenConfetti: (state, action) => {
      state.destinationToken = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCountryCode.fulfilled, (state, action) => {
      state.countryCode = action.payload || "US";
    });
    builder.addCase(getCountryCode.rejected, (state, action) => {
      state.countryCode = "US";
    });
  },
});

export const { updateBalances, updateTokenConfetti } = userSlice.actions;
export default userSlice.reducer;
